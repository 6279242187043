
import Vue, { PropType } from 'vue'
import taskListActions from '@/components/chisai/GCB2/taskList/actions'
import { getModa, saveCustomModa, setChangedModaNotification } from '../../../../api/chisai/GCB2'
import { Connector, Project } from '../../../../types/main'
import { CONNECTOR_TYPES } from '../../../../vars/general'
import {
    InnerMessageDHWithActivationId,
    massMailmessage,
    ModifiedMessageDH,
    sendMassMail,
} from '../../../../api/mailing'
import MessageTextEditor from '../../../inputs/MessageTextEditor/MessageTextEditor.vue'
import { IGCB2Connector, ManualCommunication } from '../../../../types/GCB2'
import messsages from '@dataheroes/messages'
import * as t from '@/store/chisai/GCB2/taskList/actionTypes'
import _ from 'lodash'
import { COMMUNICATION_ERROR_CODES, TASKLIST_COMMUNICATIONS_TYPES } from '../../../../vars/GCB2'
import checkConnectors from '../../../../helpers/functions/checkConnectors'
export default Vue.extend({
    components: { MessageTextEditor },
    props: {
        value: { type: Boolean as PropType<boolean> },
        item: { type: Object as PropType<ManualCommunication> },
    },
    data: () => ({
        loading: false as boolean,
        textMessage: '',
    }),
    computed: {
        textMessageTrim() {
            return this.textMessage.trim()
        },
        isDialog: {
            get(): boolean {
                return this.value
            },
            set(value: boolean): void {
                this.$emit('input', value)
            },
        },
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        gcb2Connector(): IGCB2Connector {
            return this.project.connectors.find(
                (el: Connector) => el.connectorType === CONNECTOR_TYPES.GCB2
            ) as IGCB2Connector
        },
    },
    watch: {
        isDialog() {
            this.initData()
        },
    },
    methods: {
        initData() {
            this.textMessage = this.item.textMessage ?? ''
        },
        async contactAction(communication: ManualCommunication) {
            this.loading = true
            let sendMessageResponse = { error: null, data: null } as any
            try {
                const messages = messsages.getMessages({
                    projectId: this.project.id,
                    connectors: checkConnectors(this.project.connectors),
                    project: this.project,
                    userId: this.$auth.user.sub,
                    communications: [Object.assign({}, communication, { textMessage: this.textMessage })],
                })

                const msgData = (_.first(messages)! as any) as ModifiedMessageDH

                msgData.messages[0].activationId = communication.activationId
                sendMessageResponse = await sendMassMail(msgData as any)
            } catch (err) {
                console.error(err)
                this.loading = false
                this.$store.dispatch('callNotify', 'Ошибка при отправке сообщения')
                return
            }
            this.loading = false
            const errors = sendMessageResponse.data.invalidMessages

            if (errors.length) {
                this.$store.commit(`GCB2/${t.APPEND_MESSAGE_ERRORS}`, errors)
            }

            if (sendMessageResponse.error || errors.length) {
                this.$store.dispatch('callNotify', 'Ошибка при отправке сообщения')
                await taskListActions.communicationError({
                    vueInstanse: this,
                    item: communication,
                    textMessage: this.textMessage,
                    commType: TASKLIST_COMMUNICATIONS_TYPES.MESSAGE,
                    messageError: true,
                    messageErrorCode: COMMUNICATION_ERROR_CODES[errors[0].reason]
                })
                this.isDialog = false
                return
            } else {
                await taskListActions.inProgress({
                    vueInstanse: this,
                    item: communication,
                    textMessage: this.textMessage,
                    commType: TASKLIST_COMMUNICATIONS_TYPES.MESSAGE,
                })
                this.isDialog = false
            }
        },
        async changeTextMessageAction(item: any) {
            taskListActions.changeTextMessage(this, item, this.textMessage)
            this.isDialog = false
        },
    },
})
