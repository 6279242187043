<template>
    <v-row class="ma-0" align="center">
        <PhoneLink :hrefInfo="hrefInfo"></PhoneLink>
        <div class="">
            <AmoCrmLink
                :hrefInfo="hrefInfo"
                :size="iconSize"
                :btnWidth="btnWidth"
                :btnHeight="btnHeight"
            ></AmoCrmLink>
            <YClientsLink
                :hrefInfo="hrefInfo"
                :size="iconSize"
                :btnWidth="btnWidth"
                :btnHeight="btnHeight"
            ></YClientsLink>
            <ProfSalonLink
                :hrefInfo="hrefInfo"
                :size="iconSize"
                :btnWidth="btnWidth"
                :btnHeight="btnHeight"
            ></ProfSalonLink>
            <SonlineLink
                :hrefInfo="hrefInfo"
                :size="iconSize"
                :btnWidth="btnWidth"
                :btnHeight="btnHeight"
            ></SonlineLink>
            <AltegioLink :hrefInfo="hrefInfo" :size="iconSize" :btnWidth="btnWidth" :btnHeight="btnHeight" />
            <DikidiLink :hrefInfo="hrefInfo" :size="iconSize" :btnWidth="btnWidth" :btnHeight="btnHeight" />
            <WhatsappLink
                :hrefInfo="hrefInfo"
                :whatsappLinkSettings="whatsappLinkSettings"
                :size="iconSize"
                :btnWidth="btnWidth"
                :btnHeight="btnHeight"
            ></WhatsappLink>
        </div>
    </v-row>
</template>
<script>
import YClientsLink from '@/components/iconLinks/YClientsLink'
import WhatsappLink from '@/components/iconLinks/WhatsappLink'
import ProfSalonLink from '@/components/iconLinks/ProfSalonLink'
import DikidiLink from '@/components/iconLinks/DikidiLink.vue'
import PhoneLink from '@/components/iconLinks/PhoneLink.vue'
import AltegioLink from '@/components/iconLinks/AltegioLink.vue'
import SonlineLink from './SonlineLink.vue'
import AmoCrmLink from './AmoCrmLink.vue'

export default {
    components: {
        YClientsLink,
        WhatsappLink,
        ProfSalonLink,
        SonlineLink,
        PhoneLink,
        AltegioLink,
        DikidiLink,
        AmoCrmLink,
    },
    computed: {
        whatsappLinkSettings() {
            return this.connector?.whatsappLinkSettings
        },
    },
    data: () => ({
        iconSize: 14,
        btnWidth: 21,
        btnHeight: 21,
    }),
    props: {
        hrefInfo: {
            type: Array,
            default: () => [],
        },
        connector: {
            type: Object,
            default: () => {},
        },
    },
}
</script>
