
import Vue, { PropType } from 'vue'
import * as t from '@/store/chisai/GCB2/actionTypes'
import {
    TASKLIST_ACTION_TYPES,
    TASKLIST_STATUSES,
    TASKLIST_COMMUNICATIONS_TYPES,
} from '../../../../vars/GCB2'
import taskListActions from '@/components/chisai/GCB2/taskList/actions'
import ModaDialog from './ModaDialog.vue'
import { Project } from '../../../../types/main'
import SendMessageDialog from './SendMessageDialog.vue'
import { ManualCommunication } from '../../../../types/GCB2'
import { useGcb2Store } from '../../../../store/stores/gcb2/gcb2'
import { mapStores } from 'pinia'
import { CONNECTOR_TYPES } from '../../../../vars/general'
import useSendMessageDisabled from '../hooks/useSendMessageDisabled'
export default Vue.extend({
    components: { ModaDialog, SendMessageDialog },
    props: {
        item: { type: Object as PropType<any> },
    },
    data: () => ({
        TASKLIST_ACTION_TYPES,
        TASKLIST_STATUSES,
        isPostponeDialog: false,
        changeCommentMode: null as null | 'ONLY_COMMENT' | 'DONT_REMIND',
        isChangeCommentDialog: false,
        isSendMessageDialog: false,
        isModaDialog: false,
        activeItem: null,
    }),
    computed: {
        ...mapStores(useGcb2Store),
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        showActionModalOnContact(): boolean {
            const gcb2Connector = this.project.connectors.find(
                el => el.connectorType === CONNECTOR_TYPES.GCB2
            )
            return Boolean(gcb2Connector!.showActionModalOnContact)
        },
        userId(): string {
            return this.$store.getters.myUser.sub
        },
        loadingActionsCommunicationIds(): number[] {
            return this.$store.state.GCB2.taskList.loadingActionsCommunicationIds
        },
        callActionDisabled(): boolean {
            return this.$store.state.GCB2.taskList.disabledCallCommunicationIds.includes(
                this.item.communicationId
            )
        },
        buttonsDisabled(): boolean {
            if (this.item) {
                return this.loadingActionsCommunicationIds.includes(this.item.communicationId)
            } else {
                return false
            }
        },
        waBtnDisabled(): boolean {
            const whatsappStatus = this.$store.state.whatsappStatus
            if (!whatsappStatus || whatsappStatus?.statusSubsystem !== 'on') return false

            const btnStatus = whatsappStatus.aliveStatus === 'dead' ? true : false
            return btnStatus
        },
    },
    methods: {
        isActionAvailable: taskListActions.isActionAvailable,
        isActionsContextMenuAvailable(item: any) {
            if (item.status === TASKLIST_STATUSES.CONTACTED_AND_COME) return false
            else return true
        },
        openActionModal(item: ManualCommunication, actionType: string) {
            this.gcb2Store.openActionModal({
                actionType,
                communicationId: item.communicationId,
                opened: true,
            })
        },

        openModaDialog(item: any) {
            this.activeItem = item
            this.isModaDialog = true
        },

        openSendMessageDialog(item: any) {
            this.activeItem = item
            this.isSendMessageDialog = true
        },
        async dontContactAction(item: any) {
            await taskListActions.dontContact(this, item)
        },
        async remindAction(item: any) {
            await taskListActions.remind(this, item)
        },
        async makeCall(item: any) {
            await taskListActions.makeCall({ vueInstanse: this, item, project: this.project })
        },
        async contact(item: any) {
            if (this.showActionModalOnContact) {
                await this.openActionModal(item, TASKLIST_ACTION_TYPES.CONTACT)
            } else {
                await taskListActions.contact({
                    vueInstanse: this,
                    item,
                    commType: TASKLIST_COMMUNICATIONS_TYPES.NO,
                })
            }
        },
    },
    setup() {
        let { findError } = useSendMessageDisabled()

        return { findError }
    },
})
