import { ManualCommunication } from "@root/src/types/GCB2"
import { computed, getCurrentInstance, ref, watch } from "vue"

export default function () {
    const waBtnDisabled = computed(()=>{
        const root = getCurrentInstance().proxy
        const whatsappStatus = root.$store.state.whatsappStatus
        if (whatsappStatus?.statusSubsystem !== 'on'  ) return false
        if (whatsappStatus.aliveStatus === false) return true
        if ( !whatsappStatus || whatsappStatus?.statusSubsystem !== 'on'  ) return false
        const btnStatus = whatsappStatus.aliveStatus === "dead" ? true : false
        return btnStatus
    })

    

    const findError = ((communications : ManualCommunication[]|ManualCommunication) => {

    const toShortPhoneError = (() => {
        if (Array.isArray(communications)) {
            return communications.some((item) => item.hrefInfo[0].phone.replace(/\D/g, '').length < 11)
        } else {
            return communications.hrefInfo[0].phone.replace(/\D/g, '').length < 11
        }
    })
        
    if (waBtnDisabled.value) return "Бот Whatsapp отключен"
        if (toShortPhoneError()) {
            if (Array.isArray(communications)) {
                return "Один из номеров телефонов длиной меньше 11 цифр"
            } else {
                return "Номер телефона короче 11 цифр"
            }
        }
    })

    return {
        findError
    }
}