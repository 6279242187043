<template>
    <div>
        <SpinnerLoader v-if="loading"></SpinnerLoader>
        <div v-else>
            <v-row class="ma-0" align="center">
                <h2 class="my-10 dashboard">Список коммуникаций</h2>
            </v-row>

            <v-data-table
                :headers="headers"
                :items="formattedData"
                class="admin-report-table"
                @click:row="rowClick"
                :item-class="getHighlightedClass"
            >
                <template v-slot:[`item.communication`]="{ item }">
                    <div>
                        <span class="communication-user">{{ item.communication.user }}</span>
                    </div>
                    <div>
                        <span class="communication-date">{{
                            moment(item.communication.date).format(dateFormat)
                        }}</span>
                    </div>
                    <div>
                        <span class="communication-date">{{
                            getCommunicationTypeText(item.communicationType)
                        }}</span>
                    </div>
                    <v-tooltip right v-if="item.tags && item.tags.length">
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" class="communication-tags">
                                <div v-for="tagId in item.tags" :key="tagId" class="communication-tag">
                                    {{ getTagName(tagId) }}
                                </div>
                            </div>
                        </template>
                        <span>{{ getTagsTooltipText(item.tags) }}</span>
                    </v-tooltip>
                </template>
                <template v-slot:[`item.client`]="{ item }">
                    <div>
                        <span class="communication-user">{{ item.client.name }}</span>
                    </div>
                    <div>
                        <ClientIconsTdPart
                            :hrefInfo="item.client.hrefInfo"
                            :connector="connector"
                        ></ClientIconsTdPart>
                    </div>
                </template>
                <template v-slot:[`item.services`]="{ item }">
                    <ul>
                        <li v-for="service in item.services" :key="service">{{ service }}</li>
                    </ul>
                </template>
                <template v-slot:[`item.visitDate`]="{ item }">
                    <span>{{ item.visitDate ? moment(item.visitDate).format(dateFormat) : '' }}</span>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                    <v-tooltip bottom v-if="item.status">
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" class="status-icon">
                                <img :src="getIcon(item)" />
                            </div>
                        </template>
                        <span class="white--text">{{ item.status.title }}</span>
                    </v-tooltip>
                </template>
                <template v-slot:[`item.revenue`]="{ item }">
                    <span v-if="item.status && item.revenue" :style="`color:${getIconColor(item)}`">{{
                        item.revenue
                    }}</span>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
import { openInNewTab } from '@/utils'
import { getClientRef } from '@/components/chisai/GCB2/helpers'
import moment from 'moment'
import ClientIconsTdPart from '@root/src/components/iconLinks/ClientIconsTdPart.vue'
import HintIcon from '@root/src/components/HintIcon.vue'
import { useGcb2Store } from '@root/src/store/stores/gcb2/gcb2'
import { mapStores } from 'pinia'
import SpinnerLoader from '@root/src/components/SpinnerLoader.vue'
const STATUS_ICONS = {
    error: require('@/assets/icons/error-status.svg'),
    done: require('@/assets/icons/done-status.svg'),
    idle: require('@/assets/icons/idle-status.svg'),
}
const STATUSES_INFO = {
    1: { icon: STATUS_ICONS.done, color: '#00B74F' },
    2: { icon: STATUS_ICONS.idle, color: '#2A3B5161' },
    3: { icon: STATUS_ICONS.error, color: '#E20029' },
    4: { icon: STATUS_ICONS.idle, color: '#2A3B5161' },
    5: { icon: STATUS_ICONS.idle, color: '#2A3B5161' },
}
const communicationDateSort = (a, b) => {
    return moment(a.date).unix() - moment(b.date).unix()
}
const clientSort = (a, b) => {
    return a.name.localeCompare(b.name)
}
const statusDateSort = (a, b) => {
    return Number(a.id) - Number(b.id)
}
export default {
    name: 'GCB2CommunicationList',
    components: { ClientIconsTdPart, HintIcon, SpinnerLoader },
    props: {
        chartData: {
            type: Array,
            default: () => [],
        },
        connector: {
            type: Object,
            default: undefined,
        },
    },
    data: () => ({
        STATUSES_INFO,
        loading: true,
        dateFormat: 'DD MMM YYYY',
        headers: [
            {
                text: 'Кто коммуницировал, дата коммуникации, способ связи, теги',
                value: 'communication',
                width: '20%',
                sort: communicationDateSort,
            },
            { text: 'Клиент', width: '20%', value: 'client', sort: clientSort },
            { text: 'Действие', width: '20%', value: 'actionType' },
            { text: 'Услуга', width: '40%', value: 'services' },
            { text: 'Дата', width: '5%', value: 'visitDate' },
            { text: 'Статус', width: '5%', value: 'status', sort: statusDateSort },
            { text: 'Выручка', width: '5%', value: 'revenue' },
        ],
    }),
    computed: {
        ...mapStores(useGcb2Store),
        project() {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },

        communicactionTags() {
            return this.gcb2Store.getAllCommunicationTags(this.project.id)
        },
        formattedData() {
            return this.chartData.map(el => ({
                rawData: el,
                communication: { user: el.user_name, date: el.communicated_at },
                client: { name: el.client_name, hrefInfo: el.href_info },
                services: el.staff_service_list ? Object.values(el.staff_service_list).flat() : [],
                visitDate: el.visit_date,
                actionType: el.action_name,
                communicationType: el.action_comm_type,
                tags: el.tags,
                status: el.extended_status_id
                    ? { id: el.extended_status_id, title: el.extended_status_name }
                    : null,
                revenue: el.amount,
            }))
        },
    },
    methods: {
        openInNewTab,
        getClientRef,
        getCommunicationTypeText(communicationType) {
            if (communicationType === 'phone') return 'Звонок'
            if (communicationType === 'mess') return 'Сообщение'
            if (communicationType === 'no') return 'Нет информации о способе связи'
        },
        getTagName(tagId) {
            return this.communicactionTags.find(el => el.id === tagId)?.name ?? ''
        },
        getTagsTooltipText(tagsIds) {
            return `ТЕГИ:\n${tagsIds.map(id => this.getTagName(id)).join('\n')}`
        },
        getIcon(item) {
            if (!item.status) return
            return STATUSES_INFO[item.status.id]?.icon
        },
        getIconColor(item) {
            if (!item.status) return
            return STATUSES_INFO[item.status.id]?.color
        },
        getHighlightedClass(item) {
            if (item.rawData.id === this.gcb2Store.sidebar.item?.id) {
                return 'GCB2-highlited-row'
            }
        },
        rowClick(item) {
            this.gcb2Store.openSidebar({
                type: 'analytics',
                item: item.rawData,
                section: 'clientInfo',
            })
        },
    },
    async mounted() {
        this.loading = true
        await this.gcb2Store.fetchCommunicationTags(this.project.id)
        this.loading = false
    },
    //
}
</script>
<style lang="sass" scoped>
@import '@/vars.sass'

.admin-report-table
    cursor: pointer
    ul
        list-style-type: "—"
        padding-left: 8px
.status-icon
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer



.admin-report-table .communication-user
    font-weight: bold
.admin-report-table .communication-date
    color: $gray
.admin-report-table .communication-type
    color: $gray
.admin-report-table .communication-tags
    margin-top: 4px
    cursor: pointer
.communication-tag
    text-overflow: ellipsis
    color: $gray
    overflow: hidden
    white-space: nowrap
    width: 160px
</style>
